























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VesselEnquiryInvoiceTable from "@/components/vessel/enquiry/VesselEnquiryInvoiceTable.vue";
import StatementChargeCreateDialog from "@/components/dialogs/StatementChargeCreateDialog.vue";
import StatementChargeUpdateDialog from "@/components/dialogs/StatementChargeUpdateDialog.vue";
import Api from "@/api";
import { StatementChargeModel, StatementModel } from "@/api/generated";
import api from "@/api";

@Component({
  components: {
    VesselEnquiryInvoiceTable,
    StatementChargeCreateDialog,
    StatementChargeUpdateDialog
  }
})
export default class VesselEnquiryInvoice extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  private statement: StatementModel | null = null;
  private addChargeDialog = false;
  private editStatementCharge: StatementChargeModel = { description: "" };
  private editChargeDialog = false;
  private refreshKey = false;

  @Watch("vesselEnquiryId", { immediate: true })
  private async onVesselEnquiryIdChanged() {
    let response = await Api.StatementService.apiStatementVesselEnquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.statement = response.data;

    if (!this.statement) {
      response = await api.StatementService.apiStatementVesselEnquiryVesselEnquiryIdPost(
        this.vesselEnquiryId
      );

      this.statement = response.data;
    }
  }

  private onAddStatementCharge() {
    this.addChargeDialog = true;
  }

  private onUpdateStatementCharge(item: StatementChargeModel) {
    if (!item.statementChargeId) {
      return;
    }

    this.editStatementCharge = item;
    this.editChargeDialog = true;
  }
}
